import React from "react";
import './contacts-page.css'
import VerticalText from "../../components/vertical-text/vertical-text";
import { NavLink } from "react-router-dom";
import PageTransition from "../../components/page-transition/page-transition";
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"

function ContactsPage() {
    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - Контакты</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location="контакты" text={{ first: "КОНТАКТЫ", second: "КОНТАКТЫ" }} />
                    <section className="contacts-page__section">
                        <div className="padding-wrapper">
                            <h2 className="contacts-page__title page-title">Контакты</h2>
                            <div className="contacts-text__wrapper">
                                <p className="contacts-text">
                                    Baron Doors:
                                    <NavLink to="tel:+998972870074">
                                        +998 (97)-287-00-74
                                    </NavLink>
                                </p>
                                <p className="contacts-text">
                                    Адрес: <span> Самаркандская область, г. Самарканд, Нарпайская ул., 77А</span>
                                </p>
                                <p className="contacts-text">
                                    Электронная почта:
                                    <NavLink to="mailto:barondoors@gmail.com">
                                        barondoors@gmail.com
                                    </NavLink>
                                </p>
                            </div>
                            <div className="map-wrapper">
                                <div style={{ position: "relative", overflow: "hidden" }}>
                                    <a href="https://yandex.uz/maps/org/74968633465/?utm_medium=mapframe&utm_source=maps" style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "0px" }}>Baron Doors</a>
                                    <a href="https://yandex.uz/maps/10334/samarkand/category/doors/184107677/?utm_medium=mapframe&utm_source=maps" style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "14px" }}>Двери в Самарканде</a>
                                    <a href="https://yandex.uz/maps/10334/samarkand/category/hardware_for_windows/184107745/?utm_medium=mapframe&utm_source=maps" style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "28px" }}>Комплектующие для окон в Самарканде</a>
                                    <iframe src="https://yandex.uz/map-widget/v1/?ll=66.919449%2C39.662687&mode=search&oid=74968633465&ol=biz&z=16" width="100%" height="700" allowFullScreen={true} title="map" style={{ position: "relative", border: "none" }}></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </PageTransition>
        </>
    )
}

export default ContactsPage