import React from "react";
import './door-card.css'
import { NavLink } from "react-router-dom";



function DoorCard(props) {
    return (
        <NavLink className="door-card" to={`/catalog/door/${props.item.id}`} style={{ backgroundImage: `url(/api/catalog/${props.item.folder_title}/card.webp)` }}>
            {/* <img className="door-card__img" src={`/api/catalog/${props.item.folder_title}/card.webp`} alt="door baron doors img card" /> */}
            <p className="door-card__title">{props.item.title}</p>
        </NavLink>
    )
}

export default DoorCard