import React from "react";
import './about-page.css'
import VerticalText from "../../components/vertical-text/vertical-text";
import Form from "../../components/form/form";
import PageTransition from "../../components/page-transition/page-transition";
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


function AboutPage() {
    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - О компании</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location="о компании" text={{ first: "О", second: "КОМПАНИИ" }} />
                    <section className="about-page__section">
                        <div className="padding-wrapper">
                            <h2 className="about-page__title page-title">О компании</h2>
                            <div className="about-page__content-block">
                                <p className="about-page__text">
                                    Добро пожаловать в мир качественных и стильных дверей! Мы рады представить вам компанию Baron Doors - вашего надежного партнера в мире дверей высшего качества.
                                </p>
                                <p className="about-page__text">
                                    <span>Baron Doors</span> - это компания, специализирующаяся на производстве и продаже дверей, которые превращают обыденное в исключительное. Наша миссия - предоставлять клиентам продукцию, которая не только обеспечивает надежную защиту и безопасность, но и придает вашему дому индивидуальный стиль и уют.
                                </p>
                                <p className="about-page__text">
                                    <span>Широкий ассортимент:</span> Мы предлагаем разнообразие дверей, включая внутренние и входные двери, двери с различными стилями, цветами и отделками, чтобы удовлетворить ваши потребности и предпочтения.
                                </p>
                                <p className="about-page__text">
                                    <span>Качество на первом месте:</span> Мы гордимся своими продуктами высшего качества, изготавливаемыми из прочных и долговечных материалов. Наши двери прошли строгий контроль качества, чтобы обеспечить безупречную производительность.
                                </p>
                                <p className="about-page__text">
                                    <span>Индивидуальный подход:</span> Мы понимаем, что каждый клиент уникален, поэтому предоставляем возможность заказать двери по индивидуальным размерам и дизайну, чтобы они идеально вписывались в ваш интерьер.
                                </p>
                                <p className="about-page__text">
                                    <span>Профессиональные консультации:</span> Наши опытные специалисты всегда готовы помочь вам с выбором подходящих дверей и ответить на все ваши вопросы.
                                </p>
                                <p className="about-page__text">
                                    <span>Доставка и установка:</span> Мы обеспечиваем удобную доставку и профессиональную установку наших дверей, чтобы вы могли наслаждаться ими сразу после покупки.
                                </p>
                            </div>
                            <div className="about-page__content-block">
                                <p className="about-page__text">
                                    Мы обслуживаем как частных клиентов, так и бизнес-заказчиков. Наши двери можно увидеть в домах, офисах, магазинах и других коммерческих и жилых объектах. Мы гордимся долгосрочными отношениями с нашими клиентами и стремимся удовлетворить их потребности на высшем уровне.
                                </p>
                                <p className="about-page__text">
                                    Если вы ищете качественные двери, которые принесут комфорт и стиль в ваш дом, обратитесь к нам. Мы всегда готовы помочь вам выбрать идеальные двери для ваших потребностей.
                                </p>
                                <p className="about-page__text">
                                    Свяжитесь с нами по телефону или электронной почте, чтобы получить бесплатную консультацию и узнать больше о наших продуктах и услугах.
                                </p>
                                <p className="about-page__text">
                                    Baron Doors - ваш путь к безопасности, стилю и качеству. Добро пожаловать!
                                </p>
                            </div>
                            <Form />
                        </div>
                    </section>
                </main>
            </PageTransition>
        </>
    )
}

export default AboutPage