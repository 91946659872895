import React, { useContext } from "react";
import './basket.css'
import { Context } from "../../context/context";
import { NavLink, useLocation } from "react-router-dom";

function Basket() {
    const value = useContext(Context)
    const location = useLocation()

    return (
        <NavLink className={value.basket.length > 0 ? location.pathname === "/basket" ? "basket" : "basket basket--active" : "basket"} to={"/basket"}>
            <i className="fa-solid fa-cart-shopping basket-icon"></i>
            <span className="basket-count">{value.basket.length}</span>
        </NavLink>
    )
}


export default Basket