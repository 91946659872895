import React, { useContext, useState } from "react";
import './door-details.css'
import { Context } from "../../context/context"

function DoorDetails(props) {

    const { data } = props

    const [mainColor, setMainColor] = useState(0)
    const [mainImg, setMainImg] = useState(`/api/catalog/${data.folder_title}/type/${data.catalog[0].folder_title}/${data.catalog[0].doors[0].img}`)
    const [mainImgModel, setMainImgModel] = useState(data.catalog[0].doors[0].title)
    const [mainImgColor, setMainImgColor] = useState(data.catalog[mainColor].color_title)
    const [checkBasketToggle, setCheckBasketToggle] = useState(false)

    const value = useContext(Context)

    function changeMainColor(id) {
        setMainColor(id)
        changeMainImg(`/api/catalog/${data.folder_title}/type/${data.catalog[id].folder_title}/${data.catalog[id].doors[0].img}`, data.catalog[id].doors[0].title)
        setMainImgColor(data.catalog[id].color_title)

        let items = document.querySelectorAll(".door-details__types-img");
        for (let i = 0; i < items.length; i++) {
            if (i === 0) {
                items[0].classList.add("door-details__types-img--active")
            } else {
                items[i].classList.remove("door-details__types-img--active")
            }
        }
    }

    function changeMainImg(img, model) {
        setMainImg(img)
        setMainImgModel(model)
    }

    const borderItem = (defaul_class, active_class) => (e) => {
        if (e.target.classList.value === defaul_class) {
            let items = document.querySelectorAll(`.${defaul_class}`);
            for (let i = 0; i < items.length; i++) {
                items[i].classList.remove(active_class)
            }
            e.target.classList.add(active_class)
        }
    }

    function basket(mainImg) {
        setCheckBasketToggle(!checkBasketToggle)
        let count = 0
        value.basket.forEach(item => {
            if (item.img === mainImg) {
                return count++
            }
        });

        if (count > 0) {
            return false
        } else {
            value.addBasket(data.title, mainImgModel, mainImgColor, mainImg)
        }

    }

    function checkBasket(mainImg) {
        if (value.basket.length === 0) {
            return (
                <button className="door-details__btn" onClick={() => basket(mainImg)}>
                    Добавить в корзину
                </button>
            )
        } else {
            const btnStatus = value.basket.find(item => {
                return item.img === mainImg
            })

            if (btnStatus) {
                return (
                    <button className="door-details__btn door-details__btn--delete" onClick={() => value.deleteBasket(mainImg)}>
                        Удалить
                    </button >
                )
            } else {
                return (
                    <button className="door-details__btn" onClick={() => basket(mainImg)}>
                        Добавить в корзину
                    </button>
                )
            }
        }
    }

    return (
        <>
            <div className="door-details__wrapper">
                <div className="door-details__img-wrapper">
                    <img className="door-details__img" src={mainImg} alt="door baron doors img details" />
                    {checkBasket(mainImg)}

                </div>
                <div className="door-details">
                    <div className="door-details__colors-wrapper" onClick={borderItem("door-details__colors-img", "door-details__colors-img--active")}>
                        {
                            data.catalog.map((item, id) => {
                                if (id === 0) {
                                    return (
                                        <div key={id} className="door-details__colors-block" onClick={() => changeMainColor(id)}>
                                            <img className="door-details__colors-img door-details__colors-img--active"
                                                src={`/api/catalog/${data.folder_title}/color/${item.color_img}`} alt="door color baron doors img" />
                                            <p className="door-details__colors-title">{item.color_title}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={id} className="door-details__colors-block" onClick={() => changeMainColor(id)}>
                                            <img className="door-details__colors-img"
                                                src={`/api/catalog/${data.folder_title}/color/${item.color_img}`} alt="door color baron doors img" />
                                            <p className="door-details__colors-title">{item.color_title}</p>
                                        </div>
                                    )
                                }
                            })
                        }

                    </div>
                    <div className="door-details__types-wrapper" onClick={borderItem("door-details__types-img", "door-details__types-img--active")}>
                        {
                            data.catalog[mainColor].doors.map((item, id) => {
                                if (id === 0) {
                                    return (
                                        <div key={id} className="door-details__types-block"
                                            onClick={() => changeMainImg(`/api/catalog/${data.folder_title}/type/${data.catalog[mainColor].folder_title}/${item.img}`, item.title)}>
                                            <img className="door-details__types-img door-details__types-img--active"
                                                src={`/api/catalog/${data.folder_title}/type/${data.catalog[mainColor].folder_title}/${item.img}`}
                                                alt="door type baron doors img" />
                                            <p className="door-details__types-title">{item.title}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={id} className="door-details__types-block"
                                            onClick={() => changeMainImg(`/api/catalog/${data.folder_title}/type/${data.catalog[mainColor].folder_title}/${item.img}`, item.title)}>
                                            <img className="door-details__types-img"
                                                src={`/api/catalog/${data.folder_title}/type/${data.catalog[mainColor].folder_title}/${item.img}`}
                                                alt="door type baron doors img" />
                                            <p className="door-details__types-title">{item.title}</p>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div >
            <div className="door-details__text-wrapper">
                <p className="door-details__text">{data.info.first_text}</p>
                <p className="door-details__text">{data.info.second_text}</p>
            </div>
        </>

    )
}

export default DoorDetails