import React, { useContext, useState } from "react";
import './catalog-page.css'
import { Context } from "../../context/context";
import VerticalText from "../../components/vertical-text/vertical-text";
import CatalogMenu from "../../components/catalog-menu/catalog-menu";
import DoorCard from "../../components/door-card/door-card";
import PageTransition from "../../components/page-transition/page-transition"
import doorsSections from "../../data/doors-sections.json"
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


function CatalogPage(props) {

    const { data, title, text } = props

    const value = useContext(Context)

    const [filterCollectionTitle, setFilterCollectionTitle] = useState("Все двери")
    const [filterCollection, setFilterCollection] = useState(value.doorTypesFilter || "all-doors")


    function dataFiltering(title, type) {
        setFilterCollectionTitle(title)
        setFilterCollection(type)
    }

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - {text.first[0].toUpperCase() + text.first.slice(1).toLowerCase()} {text.second.toLowerCase()} {filterCollectionTitle}</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location={title} text={{ first: text.first, second: text.second }} />
                    <section className="catalog-page__section">
                        <div className="padding-wrapper">
                            <h2 className="catalog-page__title page-title">{filterCollectionTitle}</h2>
                            <div className="catalog-page__doors-wrapper">
                                <CatalogMenu />
                                <div className="catalog__filter">
                                    {
                                        doorsSections.sections.interior_doors.map((item, id) => (
                                            <p key={id}
                                                className={filterCollection === item.type ? "catalog__filter-text catalog__filter-text--active" : "catalog__filter-text"}
                                                onClick={() => dataFiltering(item.title, item.type)}>
                                                {item.title}
                                            </p>
                                        ))
                                    }
                                </div>
                                <div className="catalog-page__doors-catalog">
                                    {
                                        data.map((item, id) => {
                                            if (filterCollection == "all-doors") {
                                                return <DoorCard key={id} item={item} />
                                            }
                                            if (filterCollection == item.info.type) {
                                                return <DoorCard key={id} item={item} />
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </PageTransition >
        </>
    )
}


export default CatalogPage