import React from "react";
import './home-page.css'
import Intro from "../../components/intro/intro";
import WeProduce from "../../components/we-produce/we-produce";
import DoorTypes from "../../components/door-types/door-types";
import Form from "../../components/form/form";
import VerticalText from "../../components/vertical-text/vertical-text";
import Preloader from "../../components/preloader/preloader";
import PageTransition from "../../components/page-transition/page-transition";
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


function HomePage() {

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors</title>
            </Helmet>
            <PageTransition>
                <main className="main" >
                    <Preloader />
                    <VerticalText location="главная" text={{ first: "BARON", second: "DOORS" }} />
                    <Intro />
                    <div className="padding-wrapper">
                        <WeProduce />
                        <DoorTypes />
                        <Form />
                    </div>
                </main >
            </PageTransition>
        </>
    )
}
export default HomePage