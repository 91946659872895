import React, { useContext } from "react";
import './door-types.css'
import { NavLink } from "react-router-dom";
import { Context } from "../../context/context";

import img_1 from '../../img/door-types/1.webp'
import img_2 from '../../img/door-types/2.webp'
import img_3 from '../../img/door-types/3.webp'
import img_4 from '../../img/door-types/4.webp'
import img_5 from '../../img/door-types/5.webp'
import img_6 from '../../img/door-types/6.webp'


function DoorTypes() {

    const value = useContext(Context)

    const doorTypes = [
        {
            link: "/catalog/interior-doors",
            img: img_1,
            filter: "classic",
            title: "Классика",
            subtitle: "Двери в стиле классика – это вечный символ изящества и роскоши. Их дизайн вдохновлен историческими архитектурными формами и орнаментами. Эти двери создают атмосферу тепла и уюта.",
        },
        {
            link: "/catalog/interior-doors",
            img: img_2,
            filter: "Неоклассика",
            title: "neoclassic",
            subtitle: "Наша дверь в стиле неоклассика — это сочетание элегантности и удобства. Вы открываете не просто дверь, а входите в мир стильного дизайна и комфорта.",
        },
        {
            link: "/catalog/interior-doors",
            img: img_3,
            filter: "modern",
            title: "Модерн",
            subtitle: "Двери в стиле модерн представляют собой идеальное сочетание функциональности и современного дизайна. Они обладают чистыми линиями, минималистическими формами и используют современные материалы.",
        },
        {
            link: "/catalog/interior-doors",
            img: img_4,
            filter: "veneer",
            title: "Шпон",
            subtitle: "Двери, покрытые натуральным шпоном, придают интерьеру теплоту и натуральный характер. Шпон – это тонкий слой натурального дерева, который позволяет сохранить естественную текстуру и узор древесины.",
        },
        {
            link: "/catalog/interior-doors",
            img: img_5,
            filter: "ral",
            title: "Окраска по RAL",
            subtitle: "Двери с окраской по стандартной палитре RAL – это отличный способ придать интерьеру индивидуальность и цветовое решение. Вы можете выбрать любой цвет из RAL-палитры, чтобы двери сочетались с другими элементами дизайна вашего помещения.",
        },
        {
            link: "/catalog/interior-doors",
            img: img_6,
            filter: "aluminum",
            title: "Алюминиевые",
            subtitle: "Алюминиевые двери – это современное решение, обладающее прочностью и стойкостью к внешним воздействиям. Они идеально подходят для современных интерьеров и подразумевают использование алюминиевых профилей.",
        }

    ];

    return (
        <section className="door-types">
            <h2 className="door-types__title">
                Типы дверей
            </h2>
            <div className="door-types__types-wrapper">
                {
                    doorTypes.map((item, id) => {
                        return (
                            <NavLink className="door-types__types-block" to={item.link} key={id} onClick={() => value.doorTypesFilterFunction(item.filter)}>
                                <img className="door-types__types-block--img" src={item.img} alt="door-types img baron doors" />
                                <div>
                                    <p className="door-types__types-block--title">{item.title}</p>
                                    <span className="door-types__types-block--subtitle">{item.subtitle}</span>
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
        </section>
    )
}


export default DoorTypes