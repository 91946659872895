import React from "react";
import './page-transition.css'
import { motion } from "framer-motion"

function PageTransition(props) {
    return (
        <>
            {props.children}
            <motion.div
                className="slide-in"
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 1 }}
                transition={{ duration: .8 }}
            />
            <motion.div
                className="slide-out"
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: .8 }}
            />
        </>
    )
}

export default PageTransition