import React, { useContext, useState } from "react";
import './checkout-form.css'
import { SendMessageBasket } from "../../utils/send-message/send-message";
import { Context } from "../../context/context";


function CheckoutForm() {

    const [name, setName] = useState('')
    const [tel, setTel] = useState('')
    const [address, setAddress] = useState('')
    const [text, setText] = useState('')

    const value = useContext(Context)

    const changeName = (e) => {
        setName(e.target.value)
    }
    const changeTel = (e) => {
        setTel(e.target.value)
    }
    const changeAddress = (e) => {
        setAddress(e.target.value)
    }
    const changeText = (e) => {
        setText(e.target.value)
    }


    function createMessage(e) {
        e.preventDefault()
        if (name !== '') {
            document.querySelector("input[name='name']").classList.remove("input-empty")
            if (tel !== '') {
                document.querySelector("input[name='tel']").classList.remove("input-empty")
                if (address !== '') {
                    document.querySelector("input[name='location']").classList.remove("input-empty")
                    SendMessageBasket(name, tel, address, text, value.basket)
                    value.formSuccessToggle()
                } else {
                    document.querySelector("input[name='location']").classList.add("input-empty")
                }
            } else {
                document.querySelector("input[name='tel']").classList.add("input-empty")
            }
        } else {
            document.querySelector("input[name='name']").classList.add("input-empty")
        }

    }

    return (
        <div className={value.showCheckoutForm ? "checkout-form__wrapper checkout-form__wrapper--active" : "checkout-form__wrapper"}>
            <form action="" className="form">
                <i className="fa-solid fa-xmark form-close-icon" onClick={value.checkoutFormToggle}></i>
                <label htmlFor="form__input-name" className="form__input-label">
                    <i className="fa-solid fa-user form__input-icon"></i>
                    <input type="text" name="name" className="form__input" id="form__input-name" placeholder="Ваше имя" onChange={changeName} defaultValue={name} />
                </label>
                <label htmlFor="form__input-tel" className="form__input-label">
                    <i className="fa-solid fa-phone form__input-icon"></i>
                    <input type="text" name="tel" className="form__input" id="form__input-tel" placeholder="Ваше телефон" onChange={changeTel} defaultValue={tel} />
                </label>
                <label htmlFor="form__input-location" className="form__input-label">
                    <i className="fa-solid fa-location-dot form__input-icon"></i>
                    <input type="text" name="location" className="form__input" id="form__input-location" placeholder="Адрес" onChange={changeAddress} defaultValue={address} />
                </label>
                <label htmlFor="form__input-message" className="form__input-label">
                    <i className="fa-solid fa-envelope form__input-icon"></i>
                    <textarea name="message" className="form__input form__textarea" id="form__input-message" placeholder="Сообщение" onChange={changeText} defaultValue={text}></textarea>
                </label>
                <div className="form__btn-block">
                    <button className="form__btn" onClick={(e) => createMessage(e)}>Отправить</button>
                </div>
            </form >
        </div >
    )
}

export default CheckoutForm