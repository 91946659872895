import React, { useContext } from "react";
import './bar.css'
import logo from '../../img/logo.webp'
import { NavLink } from "react-router-dom";
import { Context } from "../../context/context";


function Bar() {
    const value = useContext(Context)

    return (
        <div className="column bar__logo-column">
            <NavLink className="logo-link" to={"/"}>
                <img className="logo" src={logo} alt="logo baron-doors" />
            </NavLink>
            <div className="menu-btn__wrapper" onClick={value.toggleMenu}>
                <div className="menu-btn">
                    <span className={value.showMenu ? "menu-btn__line line-1 line-1--active" : "menu-btn__line line-1"} ></span>
                    <span className={value.showMenu ? "menu-btn__line line-2 line-2--active" : "menu-btn__line line-2"}></span>
                </div>
                Меню
            </div>
            <div className="logo-column__social-network-wrapper">
                <NavLink to="https://t.me/UZBEKOV777" target="_blank" className="logo-column__social-network--link">
                    <i className="fa-brands fa-telegram"></i>
                </NavLink>
                <NavLink to="https://www.instagram.com/baron_doors/" target="_blank" className="logo-column__social-network--link">
                    <i className="fa-brands fa-instagram"></i>
                </NavLink>
            </div>
        </div>
    )
}

export default Bar
