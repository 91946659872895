import React, { useContext, useState } from "react";
import './form.css'
import { SendMessage } from "../../utils/send-message/send-message";
import { Context } from "../../context/context";

function Form() {
    const [name, setName] = useState('')
    const [tel, setTel] = useState('')
    const [address, setAddress] = useState('')
    const [text, setText] = useState('')

    const value = useContext(Context)

    const changeName = (e) => {
        setName(e.target.value)
    }
    const changeTel = (e) => {
        setTel(e.target.value)
    }
    const changeAddress = (e) => {
        setAddress(e.target.value)
    }
    const changeText = (e) => {
        setText(e.target.value)
    }


    function createMessage(e) {
        e.preventDefault()
        if (name !== '') {
            document.querySelector("input[name='name']").classList.remove("input-empty")
            if (tel !== '') {
                document.querySelector("input[name='tel']").classList.remove("input-empty")
                if (address !== '') {
                    document.querySelector("input[name='location']").classList.remove("input-empty")
                    SendMessage(name, tel, address, text)
                    value.formSuccessToggle()
                } else {
                    document.querySelector("input[name='location']").classList.add("input-empty")
                }
            } else {
                document.querySelector("input[name='tel']").classList.add("input-empty")
            }
        } else {
            document.querySelector("input[name='name']").classList.add("input-empty")
        }

    }


    return (
        <section className="form-section">
            <h2 className="form__title">
                Напишите нам
            </h2>
            <div className="form-wrapper">
                <form action="" className="form">
                    <label htmlFor="form__input-name" className="form__input-label">
                        <i className="fa-solid fa-user form__input-icon"></i>
                        <input type="text" name="name" className="form__input" id="form__input-name" placeholder="Ваше имя" onChange={changeName} value={name} />
                    </label>
                    <label htmlFor="form__input-tel" className="form__input-label">
                        <i className="fa-solid fa-phone form__input-icon"></i>
                        <input type="text" name="tel" className="form__input" id="form__input-tel" placeholder="Ваше телефон" onChange={changeTel} value={tel} />
                    </label>
                    <label htmlFor="form__input-location" className="form__input-label">
                        <i className="fa-solid fa-location-dot form__input-icon"></i>
                        <input type="text" name="location" className="form__input" id="form__input-location" placeholder="Адрес" onChange={changeAddress} value={address} />
                    </label>
                    <label htmlFor="form__input-message" className="form__input-label">
                        <i className="fa-solid fa-envelope form__input-icon"></i>
                        <textarea name="message" className="form__input form__textarea" id="form__input-message" placeholder="Сообщение" onChange={changeText} value={text}></textarea>
                    </label>
                    <div className="form__btn-block">
                        <button className="form__btn" onClick={(e) => createMessage(e)}>Отправить</button>
                    </div>
                </form>
                <div className="form__contact-wrapper">
                    <h3 className="form__contact-title">
                        Контакты
                    </h3>
                    <div className="form__contact-email-block">
                        <p className="form__contact-email-title">
                            Email
                        </p>
                        <a href="mailto:barondoors@gmail.com" className="form__contact-email">
                            barondoors@gmail.com
                        </a>
                    </div>
                    <div className="form__contact-tel-block">
                        <p className="form__contact-tel-title">
                            Телефон
                        </p>
                        <a href="tel:+998972870074" className="form__contact-tel">
                            +998 (97)-287-00-74
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Form