const token = '6652711949:AAHqJFK5dBmNEOoFWgzeEffnj8KNXsHuReU'
const profileId = '-1002011220722'

export function SendMessage(name, tel, address, text) {
    let message = `Заявка❗️%0A%0A👤 Имя: ${name}%0A📞 Телефон: ${tel}%0A📍 Адресс: ${address}%0A✉️ Сообщение: ${text}%0A`
    let url = 'https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + profileId + '&text='
    let xttp = new XMLHttpRequest()
    xttp.open("GET", url + message, true)
    xttp.send()
}

export function SendMessageBasket(name, tel, address, text, basket) {
    let basketValue = '';

    basket.forEach(item => {
        basketValue += `➖➖➖%0AНазвание: ${item.title}%0AМодель: ${item.model}%0AЦвет: ${item.color}%0A`
    });

    let message = `Заявка❗️%0A%0A👤 Имя: ${name}%0A📞 Телефон: ${tel}%0A📍 Адресс: ${address}%0A✉️ Сообщение: ${text}%0A%0A🛒 Корзина:%0A${basketValue}%0A`
    let url = 'https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + profileId + '&text='
    let xttp = new XMLHttpRequest()
    xttp.open("GET", url + message, true)
    xttp.send()
}

// https://api.telegram.org/bot6652711949:AAHqJFK5dBmNEOoFWgzeEffnj8KNXsHuReU/getUpdates
