import React, { useContext } from "react";
import './preloader.css'
import { Context } from "../../context/context";
import { motion } from "framer-motion"


function Preloader() {

    const value = useContext(Context)

    if (value.lenis === undefined || value.lenis === false) { return }

    const preloaderAnimation = {
        initial: {
            height: "100vh",
        },
        animate: {
            height: 0,
            transition: {
                delay: 1.5,
                duration: 1.5,
                ease: [0.87, 0, 0.13, 1],
            },
        },
    };

    return (
        <>
            {
                value.showLoader ?
                    <div className="preloader-wrapper">
                        < motion.div className="preloader"
                            initial="initial"
                            animate="animate"
                            variants={preloaderAnimation}
                            onAnimationStart={() => value.lenis.stop()}
                            onAnimationComplete={() => (value.lenis.start(), value.setShowLoader(false))}
                        >

                            <p className="preloader-text">
                                BARON DOORS
                            </p>

                        </motion.div >
                    </div >
                    :
                    false
            }
        </>
    )
}

export default Preloader