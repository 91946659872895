import React, { useState, createContext } from "react";
import { useLenis } from "@studio-freight/react-lenis";

export const Context = createContext()

function UserContext(props) {
    const [showLoader, setShowLoader] = useState(true)
    const [showMenu, setShowMenu] = useState(false)
    const [doorTypesFilter, setDoorTypesFilter] = useState("")
    const [basket, setBasket] = useState(getFromLocalStorage() || [])
    const [showCheckoutForm, setShowCheckoutForm] = useState(false)
    const [showFormSuccess, setShowFormSuccess] = useState(false)


    function saveToLocalStorage(array) {
        localStorage.setItem('basket', JSON.stringify(array))
    }

    function getFromLocalStorage() {
        return JSON.parse(localStorage.getItem('basket'))
    }

    const lenis = useLenis(() => {
        // lenis.options.duration = 1
        lenis.options.smoothTouch = true
    })

    function lenisToggle(state) {
        if (state) {
            lenis.start()
        } else {
            lenis.stop()
        }
    }


    function toggleMenu() {
        setShowMenu(!showMenu)
        lenisToggle(showMenu)
    }

    function doorTypesFilterFunction(value) {
        setDoorTypesFilter(value)
    }

    function addBasket(title, model, color, imgDir) {
        setBasket(basket => [...basket, { title: title, model: model, color: color, img: imgDir }]);
        saveToLocalStorage([...basket, { title: title, model: model, color: color, img: imgDir }])
    }

    function deleteBasket(imgDir) {
        const newBasket = basket.filter(item => {
            if (item.img === imgDir) {
                return false
            } else {
                return item
            }
        })

        setBasket(newBasket);
        saveToLocalStorage(newBasket);
    }


    function checkoutFormToggle() {
        setShowCheckoutForm(!showCheckoutForm)
        lenisToggle(showCheckoutForm)
    }


    function formSuccessToggle() {
        setShowFormSuccess(!showFormSuccess)
        lenisToggle(showFormSuccess)
    }

    const value = {
        lenis,
        setShowLoader,
        showLoader,
        lenisToggle,
        showMenu,
        toggleMenu,
        doorTypesFilterFunction,
        doorTypesFilter,
        addBasket,
        deleteBasket,
        basket,
        checkoutFormToggle,
        showCheckoutForm,
        showFormSuccess,
        formSuccessToggle
    }


    return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export default UserContext