import React from "react";
import './catalog-menu.css'
import { NavLink } from "react-router-dom";


function CatalogMenu() {

    const isActive = ({ isActive }) => isActive ? 'catalog-menu__link catalog-menu__link--active' : 'catalog-menu__link'


    return (
        <div className="catalog-menu">
            <NavLink className={isActive} to={"/catalog/interior-doors"}>
                Межкомнатные двери
            </NavLink>
            {/* <NavLink className="catalog-menu__link">
                Складская программа
            </NavLink>
            <NavLink className="catalog-menu__link">
                Входные двери
            </NavLink>
            <NavLink className="catalog-menu__link">
                Царговые двери
            </NavLink>
            <NavLink className="catalog-menu__link">
                Корпусная мебель
            </NavLink>
            <NavLink className="catalog-menu__link">
                Декор
            </NavLink> */}
        </div>
    )
}

export default CatalogMenu