import React from "react";
import './doors-opens.css'

import img_1 from "../../img/doors-opens/1.png"
import img_2 from "../../img/doors-opens/2.png"
import img_3 from "../../img/doors-opens/3.png"
import img_4 from "../../img/doors-opens/4.png"
import img_5 from "../../img/doors-opens/5.png"
import img_6 from "../../img/doors-opens/6.png"
import img_7 from "../../img/doors-opens/7.png"
import img_8 from "../../img/doors-opens/8.png"
import img_9 from "../../img/doors-opens/9.png"
import img_10 from "../../img/doors-opens/10.png"



const opens = [
    {
        img: img_1,
        text: "Распашная дверь, открывание на себя",
    },
    {
        img: img_2,
        text: "Раздвижная дверь в пенал",
    },
    {
        img: img_3,
        text: "Дверь-книжка",
    },
    {
        img: img_4,
        text: "Обрамление проема",
    },
    {
        img: img_5,
        text: "Раздвижная перегородка вдоль стены",
    },
    {
        img: img_6,
        text: "Фрамуга верхняя",
    },
    {
        img: img_7,
        text: "Фрамуга боковая",
    },
    {
        img: img_8,
        text: "Раздвижная дверь вдоль стены",
    },
    {
        img: img_9,
        text: "Раздвижная перегородка внутри проема",
    },
    {
        img: img_10,
        text: "Раздвижная дверь внутри проёма",
    }
];


function DoorsOpens() {
    return (
        <div className="doors-opens__wrapper">
            <h2 className="doors-opens__wrapper-title">Системы открывания</h2>
            <div className="doors-opens">
                {
                    opens.map((item, id) => {
                        return (
                            <div key={id} className="doors-opens__block">
                                <img className="doors-opens__block-img" src={item.img} alt="img baron doors" />
                                <p className="doors-opens__block-text">{item.text}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DoorsOpens

