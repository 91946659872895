import React from 'react';
// import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Context from './context/context';
import { hydrate, render } from "react-dom";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Context>
//         <App />
//       </Context>
//     </BrowserRouter>
//   </React.StrictMode>
// );



const APP = (
  <React.StrictMode>
    <BrowserRouter>
      <Context>
        <App />
      </Context>
    </BrowserRouter>
  </React.StrictMode>
)

const rootElement = document.getElementById("root");

// hydrate(APP, rootElement);
render(APP, rootElement);
