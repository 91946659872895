import React, { useEffect, useState } from "react";
import './door-page.css'
import VerticalText from "../../components/vertical-text/vertical-text";
import { useNavigate, useParams } from "react-router-dom";
import DoorDetails from "../../components/door-details/door-details";
import DoorsOpens from "../../components/doors-opens/doors-opens";
import PageTransition from "../../components/page-transition/page-transition";
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


function DoorPage(props) {
    const { data } = props

    const [door, setDoor] = useState()
    const params = useParams()


    useEffect(() => {
        data.forEach(item => {
            if (item.id == params.id) {
                setDoor(item)
            }
        });
    }, [])

    const navigate = useNavigate();

    if (door === undefined || door === false) return

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - {door.title[0].toUpperCase() + door.title.slice(1).toLowerCase()}</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location={door.info.section_ru} text={{ first: door.title, second: door.title }} />
                    <section className="door-page__section">
                        <div className="padding-wrapper">
                            <h2 className="door-page__title">{door.title}</h2>
                            <button className="door-page__back-btn" onClick={() => navigate(-1)}>Назад</button>
                            <DoorDetails data={door} />
                            <DoorsOpens />
                        </div>
                    </section>
                </main>
            </PageTransition>
        </>
    )
}

export default DoorPage