import React from "react";
import './intro.css'
import intro_mov from "../../vid/intro.mov"
import intro_webm from "../../vid/intro.webm"

function Intro() {
    return (
        <section className="intro">
            <div className="intro__title-wrapper">
                <p className="intro__title">WORLD OF DOORS</p>
            </div>
            <video className="intro__video" autoPlay muted loop playsInline>
                <source src={intro_mov} type="video/quicktime" />
                <source src={intro_webm} type="video/webm" />
            </video>
        </section>
    )
}

export default Intro