import React, { useEffect, useState } from "react";
import './vertical-text.css'
import scrollTopImg from "../../img/scroll_top.svg"

function VerticalText(props) {


    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollPercentage, setScrollPercentage] = useState(0);


    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);

            // Scroll Percentage
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY;

            const currentScrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100;

            if (isNaN(currentScrollPercentage)) {
                setScrollPercentage(0);
            }
            else {
                setScrollPercentage(currentScrollPercentage.toFixed(0));
            }

        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const numberOfElements = 40;

    const text = Array.from({ length: numberOfElements }, (_, index) => (
        <span className="vertical-text"
            key={index}
            style={{ transform: `translateY(-${scrollPosition}px)` }}>
            <span>{props.text.first}</span>{props.text.second}
        </span>
    ));

    return (
        <>
            <div className="vertical-text__location-column">
                <div className="vertical-text__location">
                    {props.location}
                </div>
                <div className="vertical-text__progress-block">
                    <img className="vertical-text__progress-img" src={scrollTopImg} alt="baron doors img" style={{ transform: `rotate(${scrollPosition}deg)` }} />
                    <span className="vertical-text__progress-item">
                        {scrollPercentage}%
                    </span>
                </div>
            </div>
            <div className="vertical-text-column">
                <div className="vertical-text-wrapper">
                    {text}
                </div>
            </div>
        </>
    )
}

export default VerticalText
