import React, { useContext } from "react";
import './basket-page.css'
import { Context } from "../../context/context";
import VerticalText from "../../components/vertical-text/vertical-text";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../components/checkout-form/checkout-form";
import PageTransition from "../../components/page-transition/page-transition"
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


function BasketPage() {
    const value = useContext(Context)
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - Корзина</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location="корзина" text={{ first: "КОРЗИНА", second: "КОРЗИНА" }} />
                    <CheckoutForm />
                    <section className="basket-page__section">
                        <div className="padding-wrapper">
                            <h2 className="basket-page__title page-title">Корзина</h2>
                            <div className="basket-page__btn-wrapper">
                                <button className="basket-page__btn basket-page__back-btn" onClick={() => navigate(-1)}>Назад</button>
                                <button className="basket-page__btn basket-page__checkout-btn" onClick={value.checkoutFormToggle}>Оформить заказ</button>
                            </div>
                            <div className="basket-list__wrapper">
                                <ul className="basket-list">
                                    {
                                        value.basket.map((item, id) => (
                                            <li key={id} className="basket-item">
                                                <div className="basket-item__text-wrapper">
                                                    <p className="basket-item__text">Название: <span>{item.title}</span></p>
                                                    <p className="basket-item__text">Модель: <span>{item.model}</span></p>
                                                    <p className="basket-item__text">Цвет: <span>{item.color}</span></p>
                                                    <button className="basket-item__delete-btn" onClick={() => value.deleteBasket(item.img)}>Удалить</button>
                                                </div>
                                                <img className="basket-item__img" src={item.img} alt="img baron doors" />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </section>
                </main>
            </PageTransition>
        </>
    )
}

export default BasketPage