import React from "react";
import './video-page.css'
import VerticalText from "../../components/vertical-text/vertical-text";
import { Helmet } from "react-helmet";
import metaImg from "../../img/og-image.webp"


import YtVideo from "../../components/yt-video/yt-video";
import PageTransition from "../../components/page-transition/page-transition"

import img1 from "../../img/product/1.webp"
import img2 from "../../img/product/2.webp"
import img3 from "../../img/product/3.webp"

const video = [
    {
        link: "6Fg8733eOkg?si=vh9pZaIF9wLraFRb",
        img: img1
    },
    {
        link: "rxBERL96Pk4?si=k3G5NNq5V22_ZIVU",
        img: img2
    },
    {
        link: "fMtkLy2HrW0?si=EoyXDRc0e9o-MCMv",
        img: img3
    },

]


function VideoPage() {
    return (
        <>
            <Helmet>
                <meta property="og:url" content="https://barondoors.uz/" />
                <meta property="og:image" content={metaImg} />
                <meta name="description" content="Baron Doors – ваш надежный партнер в мире дверей высокого качества. У нас вы найдете эксклюзивные двери для дома, офиса и любого интерьера. Широкий выбор стилей, материалов и цветов. Гармония дизайна и функциональности. Закажите уникальные двери от Baron Doors и создайте неповторимый облик вашего пространства. Доставка и установка по всей стране." />
                <meta name="keywords" content="Baron Doors, Межкомнатные двери, Барон дорс, Входные двери, Эксклюзивные двери, Двери под заказ, Двери для дома, Двери для квартиры, Современные двери, Классические двери, Продажа и установка дверей, Цены на двери, Двери с гарантией, Купить двери, Купить двери в Узбекистане, Купить двери в Самарканде, Двери Самарканд, Doors Samarkand, Заказ дверей, Заказ дверей в Самарканде, Установка дверей, Двери, Дверь, Door, Doors, Baron" />
                <title>Baron Doors - Видео</title>
            </Helmet>
            <PageTransition>
                <main className="main">
                    <VerticalText location="видео" text={{ first: "ВИДЕО", second: "ВИДЕО" }} />
                    <section className="video-page__section">
                        <div className="padding-wrapper">
                            <h2 className="video-page__title page-title">Видео</h2>
                            <div className="yt-video__wrapper">
                                {/* {
                                video.map((item, id) => (
                                    <YtVideo link={item.link} img={item.img} key={id} />
                                    ))
                                } */}
                            </div>
                        </div>
                    </section>
                </main>
            </PageTransition>
        </>
    )
}

export default VideoPage