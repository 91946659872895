import React, { useContext } from "react";
import "./form-success.css"
import { Context } from "../../context/context";

function FormSuccess() {
    const value = useContext(Context)
    return (
        <div className={value.showFormSuccess ? "form-success__wrapper form-success__wrapper--active" : "form-success__wrapper"}>
            <div className="form-success__block">
                <i className="fa-solid fa-xmark form-close-icon" onClick={value.formSuccessToggle}></i>
                <p className="form-success__text">
                    Спасибо, Ваша заявка принята!
                </p>
            </div>
        </div>
    )
}


export default FormSuccess