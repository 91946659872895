import React from "react";
import "./footer.css"
import { NavLink } from "react-router-dom";
import logo from "../../img/logo-BD.webp"

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__text-wrapper">
                <div className="footer__text-block">
                    <img className="footer__text-logo" src={logo} alt="logo baron doors" />
                    <p className="footer__text">
                        Двери важны не только как практичный элемент интерьера, но и как гарант безопасности вашего дома. Ваш выбор дверей - это не просто декор, это инвестиция в комфорт и безопасность вашей семьи.
                    </p>
                </div>
                <div className="footer__social-network-wrapper">
                    <p className="footer__social-network-title">
                        Подписывайтесь
                    </p>
                    <div className="footer__social-network-block">
                        <NavLink to="https://t.me/UZBEKOV777" target="_blank" className="footer__social-network-link">
                            <i className="fa-brands fa-telegram footer__social-network"></i>
                        </NavLink>
                        <NavLink to="https://www.instagram.com/baron_doors/" target="_blank" className="footer__social-network-link">
                            <i className="fa-brands fa-instagram footer__social-network"></i>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="footer__nav-wrapper">
                <p className="footer__nav-rights-text">
                    2000 - 2023 © Baron Doors. Все права защищены.
                </p>
                <nav className="footer__nav">
                    <ul className="footer__nav-list">
                        <li className="footer__nav-item">
                            <NavLink className="footer__nav-link" to={"/catalog/interior-doors"}>
                                Каталог
                            </NavLink>
                        </li>
                        <li className="footer__nav-item">
                            <NavLink className="footer__nav-link" to={"/about"}>
                                О компании
                            </NavLink>
                        </li>
                        <li className="footer__nav-item">
                            <NavLink className="footer__nav-link" to={"/contacts"}>
                                Контакты
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    )
}

export default Footer