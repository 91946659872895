import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './layout/layout';
import HomePage from './pages/home-page/home-page';
import AboutPage from './pages/about-page/about-page';
import VideoPage from './pages/video-page/video-page';
import WorksPage from './pages/works-page/works-page';
import ContactsPage from './pages/contacts-page/contacts-page';
import CatalogPage from './pages/catalog-page/catalog-page';
import DoorPage from './pages/door-page/door-page';
import BasketPage from './pages/basket-page/basket-page';
import { ReactLenis } from '@studio-freight/react-lenis'
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';

function App() {
  const [data, setData] = useState()
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 900);
  }, [location]);



  useEffect(() => {
    const apiUrl = 'https://barondoors.uz/api/api.php'
    axios.get(apiUrl).then((resp) => {
      const data = resp.data;
      setData(data);
    });
  }, [setData]);


  if (data === undefined || data === null) return


  return (
    <Layout>
      <ReactLenis root>
        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route exact path='/' element={<HomePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/catalog/interior-doors' element={<CatalogPage data={data} title="Межкомнатные двери" text={{ first: "МЕЖКОМНАТНЫЕ", second: "ДВЕРИ" }} />} />
            {/* <Route path='/catalog/entrance-doors' element={<CatalogPage data={data} title="Входные двери" text={{ first: "ВХОДНЫЕ", second: "ДВЕРИ" }} />} /> */}
            <Route path='/works' element={<WorksPage />} />
            <Route path='/video' element={<VideoPage />} />
            <Route path='/contacts' element={<ContactsPage />} />
            <Route path='/basket' element={<BasketPage />} />
            <Route path='/catalog/door/:id' element={<DoorPage data={data} />} />
            <Route path='*' element={<>404</>} />
          </Routes>
        </AnimatePresence >
      </ReactLenis>
    </Layout>
  );
}

export default App;
