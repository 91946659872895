import React from "react";
import './layout.css'
import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import Bar from "../components/bar/bar";
import Basket from "../components/basket/basket";
import FormSuccess from "../components/form-success/form-success";

function Layout(props) {
    return (
        <div className="layout">
            <Menu />
            <Bar />
            <Basket />
            <FormSuccess />
            <>
                {props.children}
            </>
            <Footer />
        </div >
    )
}

export default Layout