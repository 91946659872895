import React, { useContext } from "react";
import './menu.css'
import { Context } from "../../context/context";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";


function Menu() {

    const value = useContext(Context)

    const variants = {
        visible: { y: 0 },
        hidden: { y: 100 },
    }


    const links = [
        {
            title: "Главная",
            link: "/",
            delay: .73,
            hiddenDelay: .18
        },
        {
            title: "О компании",
            link: "/about",
            delay: .76,
            hiddenDelay: .15
        },
        {
            title: "Каталог",
            link: "/catalog/interior-doors",
            delay: .79,
            hiddenDelay: .12
        },
        {
            title: "Наши работы",
            link: "/works",
            delay: .82,
            hiddenDelay: .09
        },
        {
            title: "Видео",
            link: "/video",
            delay: .85,
            hiddenDelay: .06
        },
        {
            title: "Контакты",
            link: "/contacts",
            delay: .88,
            hiddenDelay: .03
        },
    ]

    const isActive = ({ isActive }) => isActive ? 'nav-link nav-link--active' : 'nav-link'

    function catalogLinkColor(link) {
        const pathname = window.location.pathname.includes('catalog');

        if (link.includes('catalog')) {
            return pathname ? { color: "var(--gold)" } : {}
        }
    }


    return (
        <div className={value.showMenu ? "menu menu--active" : "menu"}>
            <nav className="nav">
                <ul className="nav-list">
                    {value.showMenu ?
                        links.map((item, id) => (
                            < li className="nav-item" key={id} >
                                <motion.span className="nav-item--span"
                                    initial="hidden"
                                    animate="visible"
                                    variants={variants}
                                    transition={{ duration: .3, delay: item.delay }}>
                                    <NavLink className={isActive} style={catalogLinkColor(item.link)} to={item.link} onClick={value.toggleMenu}>{item.title}</NavLink>
                                </motion.span>
                            </li>
                        ))
                        :
                        links.map((item, id) => (
                            < li className="nav-item" key={id} >
                                <motion.span className="nav-item--span"
                                    initial="visible"
                                    animate="hidden"
                                    variants={variants}
                                    transition={{ duration: .3, delay: item.hiddenDelay }}>
                                    <NavLink className={isActive} style={catalogLinkColor(item.link)} to={item.link}>{item.title}</NavLink>
                                </motion.span>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div >
    )
}


export default Menu   