import React from "react";
import './we-produce.css'
import { NavLink } from "react-router-dom";

import img_1 from "../../img/product/1.webp"
import img_2 from "../../img/product/2.webp"
import img_3 from "../../img/product/3.webp"
import img_4 from "../../img/product/4.webp"
import img_5 from "../../img/product/5.webp"
import img_6 from "../../img/product/6.webp"


function WeProduce() {

    const weProduce = [
        {
            link: "/catalog/interior-doors",
            img: img_1,
            subtitle: "Межкомнатные двери",
        },
        {
            link: "/",
            img: img_2,
            subtitle: "Складская программа",
        },
        {
            link: "/catalog/entrance-doors",
            img: img_3,
            subtitle: "Входные двери",
        },
        {
            link: "/",
            img: img_4,
            subtitle: "Царговые двери",
        },
        {
            link: "/",
            img: img_5,
            subtitle: "Корпусная мебель",
        },
        {
            link: "/",
            img: img_6,
            subtitle: "Декор",
        }
    ];

    return (
        <section className="we-produce">
            <h2 className="we-produce__title">Что мы производим</h2>
            <div className="we-produce__product-wrapper">
                {
                    weProduce.map((item, id) => {
                        return (
                            <NavLink className="we-produce__product-block" to={item.link} key={id}>
                                <img className="we-produce__product-block--img" src={item.img} alt="we-produce img baron doors" />
                                <span className="we-produce__product-block--subtitle">{item.subtitle}</span>
                            </NavLink>
                        )
                    })
                }
            </div>
        </section>
    )
}


export default WeProduce